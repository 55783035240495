// Meta information for pages that are not created via contentful

export const eventsMeta = {
  description:
    "Check out our latest events, where people are sharing their insights about their journey at Metyis and our latest technologies all around the globe.",
  title: "Events | Metyis"
};

export const locationsMeta = {
  description:
    "Our multidisciplinary teams are spread across 15 strategic locations all around the world and connected on a global scale across projects and partnerships.",
  title: "Locations | Metyis"
};

export const jobsMeta = {
  description:
    "We are constantly looking for talented individuals all over the world, who want to become part of Metyis and help expand our platform by sharing their knowledge.",
  image:
    "https://images.ctfassets.net/zuzzsqg9enty/2BXwJPWZRXWrm3GjqnKjF7/283973b74dd981e1d5e3e05db60627db/teams_background_metyis_Prancheta_1-17.png",
  title: "Available Positions | Metyis"
};

export const profilesMeta = {
  description:
    "A carefully combined council of professionals whose senior experience and business knowledge enhance our company and help it grow. Meet our senior leaders.",
  title: "Senior Leaders | Metyis"
};

export const spontaneousJobMeta = {
  description:
    " Whether you are a recent graduate, postdoc or an experienced professional - join Metyis and grow with us. We are always on the lookout for top talent.",
  title: "Spontaneous Applications | Metyis"
};
