import React, { useState } from "react";
// import { useCheckWindowSize } from "@metyis-ds/hooks";
import { Link as A } from "@metyis-ds/link";
import { Link } from "../components/Link/LinkWithPrevUrl";
// import { ESidebarDirection, ESidebarModes } from "@metyis-ds/sidebar";
import { IContent } from "../contexts/ContentContext";
import { IForm } from "../components/form/types";
import { IProfileList } from "../components/profile/types";
import CTABanner from "../components/banner/CTABanner";
import DoubleLineTitle from "../components/doubleLineTitle/DoubleLineTitle";
// import Form from "../components/form/Form";
import LazyLoadImage from "../components/image/LazyLoadImage";
import Page from "../components/page/Page";
// import { Sidebar } from "../components/sidebar/Sidebar";
import { locationsMeta } from "./meta";
// import Close from "../assets/close.svg";
import "./locations.css";

export interface ILocation {
  address: string;
  description: any;
  descriptionTitle: string;
  form: IForm;
  image: { file: { url: string }; title: string };
  leaders: IProfileList;
  mapImage: { file: { url: string }; title: string };
  name: string;
}

export default ({
  pageContext
}: {
  pageContext: { content: IContent; locations: ILocation[] };
}) => {
  const { content, locations } = pageContext;
  // const isMobile = useCheckWindowSize(900);
  // const [selectedLocation, setSelectedLocation] = useState<ILocation>();

  return (
    <Page
      content={content}
      seo={{
        description: locationsMeta.description,
        title: locationsMeta.title
      }}
    >
      <section className="locations">
        <DoubleLineTitle
          className="locations-title"
          isH1
          title="Strategic locations for"
          title2="global impact"
        />
        <div className="locations-list">
          {locations.map(location => (
            <div key={location.name} className="location">
              <div className="location-img-wrapper">
                <LazyLoadImage
                  alt={location.image.title}
                  className="location-img"
                  src={location.image.file.url}
                />
              </div>
              <div className="location-info">
                <span className="location-name">{location.name}</span>
                <span className="location-address">{location.address}</span>
                <Link to="/contact-us">
                  <A
                    color="var(--color-orange)"
                    // onClick={() => setSelectedLocation(location)}
                  >
                    Contact us
                  </A>
                </Link>
              </div>
            </div>
          ))}
        </div>
        <CTABanner
          backgroundColor="var(--color-blue)"
          image={{
            file: {
              url:
                "https://images.ctfassets.net/zuzzsqg9enty/4HxuoEyyDL7uhEGWDdt3JQ/6a5eba4d847adb05454b25f55c25282b/20230619_Metyis_Campus_clean_windows_01.webp"
            },
            title: "Metyis Campus Exterior Buidlings"
          }}
          redirectUrl="/metyis-campus"
          text={{
            text:
              "Our state-of-the-art Digital Campus inspires the pursuit of innovation in a collaborative environment where partnerships and teams can evolve together."
          }}
          textColor="var(--color-brown)"
          title="We are focused on building the future"
        />
        {/* <Sidebar
          className="sidebar"
          direction={
            isMobile ? ESidebarDirection.BOTTOM : ESidebarDirection.RIGHT
          }
          mode={ESidebarModes.OVER}
          open={!!selectedLocation}
          onClose={() => {
            setSelectedLocation(undefined);
          }}
        >
          <div className="sidebar-header">
            <h3>{selectedLocation?.name}</h3>
            <Close
              className="sidebar-header-icon"
              onClick={() => {
                setSelectedLocation(undefined);
              }}
            />
          </div>
          <div className="sidebar-body">
            {selectedLocation?.form?.formSections && (
              <Form
                {...selectedLocation.form}
                formId={`${
                  selectedLocation.form.formId
                }-${selectedLocation.name.split(" ").join("")}`}
                successPageTitle={selectedLocation.name}
              />
            )}
          </div>
        </Sidebar> */}
      </section>
    </Page>
  );
};
